// ---------------------------------------------------------------------------------------------------------------------
//!                                                       Imports
// ---------------------------------------------------------------------------------------------------------------------

// ----------------------------------------------------- Recoil --------------------------------------------------------
import { useRecoilState } from 'recoil';
import { gameStateAtom } from '../../../contexts/gameState';
// ---------------------------------------------------------------------------------------------------------------------

// ----------------------------------------------------- Assets --------------------------------------------------------
import Flag from '../../../assets/flag';
import '../styles.scss';
// ---------------------------------------------------------------------------------------------------------------------

const MinesCounter = () => {
	const [{ bombs, placedFlags }] = useRecoilState(gameStateAtom);

	return (
		<div className='statContainer'>
			<Flag />
			<p>
				{placedFlags} / {bombs}
			</p>
		</div>
	);
};

export default MinesCounter;
